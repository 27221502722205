import React, { PureComponent } from 'react';
import axios from 'axios';

import MapWithAMarkerClusterer from '../../components/MapWithAMarkerClusterer'

import style from './styles.module.css';

class Home extends PureComponent {
  globalIndex = 0;
  userId = '';
  constructor(props) {
    super(props);

    this.state = { };
  }
  componentWillMount() {
    this.setState({
      markers: [],
      isUser: false,
    });
  }

  componentDidMount() {
    this.periodicFetch(() => {
      this.fetchAllDamageLocations();
    }, 0);
  }

  periodicFetch(callback, timeout){
    setTimeout(() => {
      callback();
      this.setState( {markers: [], isUser: false});
      this.periodicFetch(callback, 1000 * 60 * 60);
    }, timeout); // update data every hour
  }

  fetchAllDamageLocations() {
    const query = new URLSearchParams(this.props.location.search);
    this.userId = query.get('userId');


    if(this.userId !== '') {
      axios.get('https://europe-west1-arevia-production.cloudfunctions.net/getDamageLocations', {params: {userId: this.userId}})
        .then(res => {
          if(res.data[0].id){
            const markers = this.buildMarkers(res);
            this.setState(state => { return {markers: state.markers.concat(markers)}});
            this.setState(state => {return {isUser: true}});
          }
          else {
            (res.data[0]).forEach(customerId => {
              axios.get('https://europe-west1-arevia-production.cloudfunctions.net/getDamageLocations', {params: {customerId: customerId}}).then(res => {
                const markers = this.buildMarkers(res);
                this.setState(state => { return {markers: state.markers.concat(markers)}});
                this.setState(state => {return {isUser: true}});
              });
            });
          }
        });
    }
  }

  buildMarkers(res) {
    return res.data.map((items) => {
      const { id, damageLocation, name, internalNumber } = items;

      this.globalIndex ++;

      return { repairOrderId: id, id: this.globalIndex, damageLocation, name, internalNumber }
    });
  }

  render() {
    if(this.userId !== '' && this.userId)
    {
      return (
        <div className={style.mapContainer}>
          <MapWithAMarkerClusterer zoomLevel={8} markers={this.state.markers} />
        </div>
      )
    }
    else {
      var divStyle = {
        color: 'black',
        paddingLeft: '1em',
        paddingTop: '1em',
        backgroundColor: 'white',
      };

      return (
        <div style={divStyle}>
          <img src="https://storage.googleapis.com/arevia-production-assets/Arevia.png" alt="Arevia"></img>
          <h1>Bitte öffnen Sie die Karte über das Arevia App-Menü.</h1>
          <a style={{fontSize: '20px'}} href="https://app.arevia.com/">Zum App</a>
        </div>
      )
    }
  }
}

export default Home;
