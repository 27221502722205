import React, { PureComponent } from 'react';
import axios from 'axios';

import MapWithAMarkerClusterer from '../../components/MapWithAMarkerClusterer'

import style from './styles.module.css';

const REPAIR_ORDER_STATE_1 = 'Ok für MS-Erstellung';
const REPAIR_ORDER_STATE_2 = 'Bei Versicherung';
const REPAIR_ORDER_STATE_3 = 'Bereit für Reparatur';
const REPAIR_ORDER_STATE_4 = 'Polizeiprotokoll fehlt';

const REPAIR_ORDER_STATES = [ REPAIR_ORDER_STATE_1, REPAIR_ORDER_STATE_2, REPAIR_ORDER_STATE_3, REPAIR_ORDER_STATE_4 ];

class FourMaps extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      [REPAIR_ORDER_STATE_1]: [],
      [REPAIR_ORDER_STATE_2]: [],
      [REPAIR_ORDER_STATE_3]: [],
      [REPAIR_ORDER_STATE_4]: []
    };
  }

  componentWillMount() {
    this.setState({});
  }

  componentDidMount() {
    this.periodicFetch(() => {
      REPAIR_ORDER_STATES.forEach(repairOrderState => this.fetchDamageLocations(repairOrderState));
    }, 0);
  }

  periodicFetch(callback, timeout){
    setTimeout(() => {
      callback();
      this.periodicFetch(callback, 1000 * 60 * 60);
    }, timeout); // update data every hour
  }

  fetchDamageLocations(state) {
    axios.get('https://europe-west1-arevia-production.cloudfunctions.net/getDamageLocations', {params: {state: state}})
      .then(res => {
        const markers = this.buildMarkers(res);
        this.setState({ [state]: markers });
      });
  }

  buildMarkers(res) {
    return res.data.map((items, index) => {
      const { damageLocation, name, internalNumber } = items;

      return { id: index, damageLocation, name, internalNumber }
    });
  }

  render() {
    return (
      <div className={style.container}>
        {REPAIR_ORDER_STATES.map((repairOrderState, idx) => {
          return (
            <div key={idx} className={style.mapContainer}>
              <div className={style.title}>
                <span className={style.titleValue}>{repairOrderState}</span>
              </div>
              <MapWithAMarkerClusterer zoomLevel={7} markers={this.state[repairOrderState]} />
            </div>
          );
        })}
      </div>
    );
  }
}

export default FourMaps;
