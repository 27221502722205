import React from 'react';
import { compose, withProps, withHandlers, withStateHandlers } from 'recompose';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer';

import style from './styles.module.css';

const { InfoBox } = require("react-google-maps/lib/components/addons/InfoBox");

const MapWithAMarkerClusterer = compose(
  withProps({
    googleMapURL: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDBXZ0-PtWvn__d6V-IffV2BGFpOev9NMU',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />, //height: `99.8%`, border: `0.1vh solid #c1c1c1`
    mapElement: <div style={{ height: `100%` }} />,
    center: { lat: 47.754056, lng: 15.094582 }
  }),
  withHandlers({
    onMarkerClustererClick: () => markerClusterer => {
    },
  }),
  withStateHandlers(
    ({ isOpen=false }) => ({ isOpen }),
    {
      onToggleOpen: ({ isOpen }) => (marker) => ({
        isOpen: !isOpen,
        marker
      })
    }
  ),
  withScriptjs,
  withGoogleMap
)(props =>
  <GoogleMap
    defaultZoom={props.zoomLevel}
    defaultCenter={{ lat: 47.735983, lng: 13.552224 }}
  >
    <MarkerClusterer
      onClick={props.onMarkerClustererClick}
      averageCenter
      enableRetinaIcons
      gridSize={60}
    >
      {props.markers.map(marker => {
        const { damageLocation, name, internalNumber } = marker;

        return (
          damageLocation.Latitude ?
          <Marker
            key={marker.id}
            name={name}
            data={{damageLocation, internalNumber}}
            position={{ lat: damageLocation.Latitude, lng: damageLocation.Longitude }}
            // onClick={props.onMarkerClick.bind(this, marker)}
            onClick={props.onToggleOpen.bind(this, marker)}
          >
            {props.isOpen && props.marker.id === marker.id &&
              <InfoBox
                onCloseClick={props.onToggleOpen}
                options={{ enableEventPropagation: true }}
              >
                <div className={style.infoBoxContainer}>
                  <h3>{internalNumber}</h3>
                  <p>{name}</p>
                  <p>Straße: <span>{damageLocation.Street}</span></p>
                  <p>KM: <span>{damageLocation.KmNumber}</span></p>
                  <p>Rfb.: <span>{damageLocation.DrivingDirection}</span></p>
                  <p>Rampe: <span>{damageLocation.Ramp}</span></p>
                  <a href={'https://app.arevia.com/repairOrders/'+ marker.repairOrderId} rel="noopener noreferrer" target="_blank">Zum Schadensfall</a>
                </div>
              </InfoBox>
            }
          </Marker> :
          ''
        );
      })}
    </MarkerClusterer>
  </GoogleMap>
);

export default MapWithAMarkerClusterer;
